import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private apiURL: string = 'https://pplus.blackbody.in';
  private serviceURL: string = 'https://service.blackbody.in';

  constructor(private http: HttpClient) { }

  makeEnquiry(first: string, last: string, phone: string, email: string, address1: string, address2: string, city: string, state: string, pincode: number, switchboard_count: number) {
    return this.http.post(this.apiURL+'/enquiry', {
      first: first,
      last: last,
      phone: phone,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      switchboard_count: switchboard_count
    });     
  }

  becomeDealer(first: string, last: string, phone: string, email: string, address1: string, address2: string, city: string, state: string, pincode: number, organization: string) {
    return this.http.post(this.apiURL+'/dealer', {
      first: first,
      last: last,
      phone: phone,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      organization: organization
    });     
  }

  workWithUs(first: string, last: string, phone: string, email: string, address1: string, address2: string, city: string, state: string, pincode: number, country: string) {
    return this.http.post(this.serviceURL+'/api/workWithUs', {
      first: first,
      last: last,
      phone: phone,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country
    });     
  }
}
